import React, { useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import Section from "./Section";
import SectionHeader from "./SectionHeader";
import Paper from "@material-ui/core/Paper";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import LinkMui from "@material-ui/core/Link";
import TwitterIcon from "@material-ui/icons/Twitter";
import { Link } from "./../util/router";

const ethers = require("ethers");
const { Contract } = require('ethers');

const useStyles = makeStyles((theme) => ({
  tableContainer: {
    marginTop: theme.spacing(0),
    boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
    borderRadius: "20px",
    overflow: "hidden",
    width: '100%'
  },
  imageCell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "left",
    gap: theme.spacing(1.5),
  },
  tokenImage: {
    width: 40,
    height: 40,
    borderRadius: "50%",
    objectFit: "cover",
  },
  noChartImage: {
    width: 285,
    height: 90,
    // borderRadius: "50%",
    objectFit: "cover",
    paddingLeft: '150px'
  },
  chartCell: {
    width: 600,
    height: 120,
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
    overflow: "hidden",
  },
  priceChart: {
    width: "100%",
    height: "100%",
    display: "flex",
    justifyContent: "center",
    position: "relative",
    overflow: "hidden",
  },
  candlestickStick: {
    position: "absolute",
    width: "6px",
    backgroundColor: theme.palette.primary.main,
    zIndex: 0,
    marginLeft: "-3px",
    transform: "translateX(-50%)",
  },
  candlestickBody: {
    position: "absolute",
    width: "20px",
    borderRadius: "2px",
    backgroundColor: theme.palette.primary.main,
    zIndex: 0,
    transform: "translateX(-50%)",
  },
  priceBar: {
    width: '10px', // Adjust bar width as needed
    height: '55px',
    margin: '2px', // Adjust spacing between bars
    backgroundColor: '#2196F3', // Default bar color
  },
  greenBar: {
    backgroundColor: "#4CAF50",
  },
  redBar: {
    backgroundColor: "#F44336",
  },
  positive: {
    color: '#4CAF50', // Green
    fontSize: '19px',
    fontWeight: '500'
  },
  negative: {
    color: '#F44336', // Red
    fontSize: '19px',
    fontWeight: '500'
  },
  tableHeader: {
    fontWeight: "bold",
    // backgroundColor: theme.palette.primary.light,
    color: theme.palette.common.white,
    backgroundColor: 'darkgreen',
  },
  tableHeaderRow: {
    backgroundColor: '#4CAF50',
    color: theme.palette.common.white,
  },
  tableCell: {
    backgroundColor: "",
    fontSize: '18px'
  },
  footer: {
    marginTop: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    fontSize: "5%",
    fontWeight: 100,
  },
  progressContainer: {
    width: "100%",
    backgroundColor: "green",
  },
  greenProgress: {
    backgroundColor: "#4CAF50",
  },
  priceLabel: {
    position: 'absolute',
    fontSize: '14px', // Increased font size for better visibility
    fontWeight: '600',
    color: theme.palette.text.secondary, // Adjust text color as needed
    // backgroundColor: '#fff', // Background color for better readability
    padding: '7px 5px', // Increased padding
    margin: '15px',
    borderRadius: '3px',
    transform: 'translateX(-50%)',
    // boxShadow: '0px 0px 5px rgba(0,0,0,0.3)', // Added shadow for better visibility
    zIndex: 1, // Ensure the label is above other elements
  },
  fullRowCell: {
    textAlign: 'center',
    fontStyle: 'italic',
    padding: theme.spacing(2),
  },
  link: {
    color: "inherit",
    lineHeight: 1,
    // fontSize: '21px', // Increased font size for better visibility
    textDecoration: 'none',
    "&:not(:last-of-type)": {
      marginRight: "1.2rem",
    },
  },
}));

const fetchTokenUSDPrice = async (lpContract) => {
  const provider = new ethers.providers.WebSocketProvider("wss://ws.pulsechain.com");

  const poolContract = new Contract(
    lpContract,
    [
      'function getReserves() public view returns (uint112 _reserve0, uint112 _reserve1, uint32 _blockTimestampLast)',
      'function token0() external view returns (address)',
      'function token1() external view returns (address)',
    ],
    provider
  );

  const [reserveA, reserveB] = await poolContract.getReserves();
  let decimalsA = 6; // Decimals of Token A (USDC)
  const decimalsB = 18; // Decimals of Token B (9INCH)

  const usdPrice = reserveB.mul(ethers.BigNumber.from(10).pow(decimalsA)).div(reserveA);
  // Calculate the price of 1 unit of Token B in terms of USDC (Token A)
  let pricePerUnit = reserveA.mul(ethers.BigNumber.from(10).pow(decimalsB)).div(reserveB);
  const formattedPricePerUnit = ethers.utils.formatUnits(pricePerUnit, decimalsA);

  return formattedPricePerUnit
}

async function fetchGraphDataForPair(pairID) {
  let plsGraphData = await fetch('https://graph.pulsechain.com/subgraphs/name/pulsechain/pulsex', {
    method: 'POST',
    body: JSON.stringify({
      query: `{
        pairs(where: { id: "${pairID.toLowerCase()}" }) {
          id
          name
          token0Price
          token1Price
          token0 {
            id
            symbol
          }
          token1 {
            id
            symbol
          }
          volumeUSD
          volumeToken0
          volumeToken1
          untrackedVolumeUSD
          reserve0
          reserve1
          pairHourData(first: 24, orderBy: hourStartUnix, orderDirection: desc) {
            id
            hourStartUnix
            hourlyVolumeUSD
            hourlyVolumeToken0
            hourlyVolumeToken1
            hourlyTxns
            reserveUSD
            reserve0
            reserve1
          }
        }
      }`
    })
  });
  
  let plsGraphJson = await plsGraphData.json()
  // console.log(plsGraphJson.data)

  return plsGraphJson.data.pairs[0]
}

async function fetchTokenListings() {
  let tokenData = await fetch('https://billowing-flower-7346.p384-cpp.workers.dev/fetch-data', {method: 'GET'});
  let tokenDataJson = await tokenData.json()
  tokenDataJson = JSON.parse(tokenDataJson.value);
  // console.log(tokenDataJson);

  const flattenedData = [ ...tokenDataJson.ethereumTokens /*, ...tokenDataJson.pulsechain*/];
  // console.log(flattenedData);
  return flattenedData;
}

async function getPast24HPriceData(pairGraphJson, usdPair, token) {
  if (!pairGraphJson || !pairGraphJson.pairHourData)
    return null;

  let knownUSDPair = 1
  if (!usdPair) {
    knownUSDPair = await fetchTokenUSDPrice(token.usdLPContractAddress);
  }

  const priceData = pairGraphJson.pairHourData.reverse(); //Reverse price data so the last 24h price is first

  return priceData.map((hourData) => {
    // Store USD prices in a key-value object
    if (!usdPair) {
      return parseFloat(knownUSDPair * parseFloat(hourData.reserve1) / parseFloat(hourData.reserve0)).toFixed(9);
    } else {
      return parseFloat(parseFloat(hourData.reserve0) / parseFloat(hourData.reserve1)).toFixed(9);
    }
  })
}

function calculate24HPercentageMove(priceData) {
  if (!priceData || priceData.length < 2) {
    throw new Error("Insufficient data to calculate percentage move");
  }

  const currentPrice = priceData[priceData.length - 1];
  const price24HrsAgo = priceData[0];

  const percentageMove = ((currentPrice - price24HrsAgo) / price24HrsAgo) * 100;

  return percentageMove.toFixed(2) //+ "%";
}

function TokenListings(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false); // State to manage loading status

  // Function to generate random price data for the past 7 days
  const generatePriceData = () => {
    const priceData = [];
    let previousPrice = Math.floor(Math.random() * 100) + 50; // Starting price
    priceData.push(previousPrice);
    
    for (let i = 1; i < 7; i++) {
      const currentPrice = previousPrice + Math.floor(Math.random() * 21) - 10; // Generate random price within +/- 10 range
      priceData.push(currentPrice);
      previousPrice = currentPrice;
    }
    
    return priceData;
  };

  // Generate random price data for the past 7 days
  const priceData = generatePriceData();
  
  const initialTokensUSDPairs = [
    // { id: 2, name: "PLS", usdPair: true, priceDeicamlsformat: 6, decimalsFormat: 8, img: 'https://tokens.app.pulsex.com/images/tokens/0xA1077a294dDE1B09bB078844df40758a5D0f9a27.png', lpContractAddress: '0x6753560538ECa67617A9Ce605178F788bE7E524E', price: 0 },
    // // { id: 3, name: "9INCH", usdPair: true, priceDeicamlsformat: 6, decimalsFormat: 8, noPriceChart: true, img: 'https://app.9inch.io/images/369/symbols/9inch.png', lpContractAddress: '0x6C5a0F22b459973A0305e2a565fc208a35A13850', price: 0 },
    // { id: 4, name: "PLSX", usdPair: false, priceDeicamlsformat: 6, decimalsFormat: 8, img: 'https://tokens.app.pulsex.com/images/tokens/0x95B303987A60C71504D99Aa1b13B4DA07b0790ab.png', lpContractAddress: '0x1b45b9148791d3a104184Cd5DFE5CE57193a3ee9', usdLPContractAddress: '0x6753560538ECa67617A9Ce605178F788bE7E524E', price: 0 },
    // { id: 5, name: "INC", usdPair: false, priceDeicamlsformat: 2, decimalsFormat: 2, img: 'https://tokens.app.pulsex.com/images/tokens/0x2fa878Ab3F87CC1C9737Fc071108F904c0B0C95d.png', lpContractAddress: '0xf808Bb6265e9Ca27002c0A04562Bf50d4FE37EAA', usdLPContractAddress: '0x6753560538ECa67617A9Ce605178F788bE7E524E', price: 0 },
  ];

  const initialXTokens= [
    // { id: 7, name: "monsters.fun", noPrice: true, noPriceChart: true, communityLink: 'https://x.com/monstersdotfun', communityNotes: 'Comming to Abstract Chain soon.', usdPair: false, priceDeicamlsformat: 6, decimalsFormat: 8, img: 'https://pbs.twimg.com/profile_images/1871545646664052736/Yd-eXqxW_400x400.jpg', lpContractAddress: '', price: 0 },
    // { id: 8, name: "Spray & Pray", noPrice: true, noPriceChart: true, communityLink: 'https://x.com/spraypray_io', communityNotes: 'Comming to Abstract Chain soon.', usdPair: false, priceDeicamlsformat: 6, decimalsFormat: 8, img: 'https://pbs.twimg.com/profile_images/1856525792974381056/9Yq69W8d_400x400.jpg', lpContractAddress: '', price: 0 },
    // { id: 9, name: "Roach Racing Club", noPrice: true, noPriceChart: true, communityLink: 'https://x.com/RoachRacingClub', communityNotes: 'Comming to Abstract Chain soon.', usdPair: false, priceDeicamlsformat: 6, decimalsFormat: 8, img: 'https://pbs.twimg.com/profile_images/1532777649176813570/_vGC1gSj_400x400.jpg', lpContractAddress: '', price: 0 },
    // { id: 10, name: "Witty", noPrice: true, noPriceChart: true, communityLink: 'https://x.com/play_witty', communityNotes: 'Comming to Abstract Chain soon.', usdPair: false, priceDeicamlsformat: 6, decimalsFormat: 8, img: 'https://pbs.twimg.com/profile_images/1875933148384837632/tzdyOJTg_400x400.jpg', lpContractAddress: '', price: 0 },
    
    // { id: 4, name: "Franky's Dinner", noPrice: true, noPriceChart: true, communityLink: 'https://x.com/frankythefrog', communityNotes: 'Comming to Abstract Chain soon.', usdPair: false, priceDeicamlsformat: 6, decimalsFormat: 8, img: 'https://pbs.twimg.com/profile_images/1874555704951934976/UXBflULN_400x400.jpg', lpContractAddress: '', price: 0 },
    // { id: 5, name: "Khuga", noPrice: true, noPriceChart: true, communityLink: 'https://x.com/Khugaverse', communityNotes: 'Comming to Abstract Chain soon.', usdPair: false, priceDeicamlsformat: 6, decimalsFormat: 8, img: 'https://pbs.twimg.com/profile_images/1805820740950016001/P7GZrSs4_400x400.jpg', lpContractAddress: '', price: 0 },
    // { id: 6, name: "77-Bit", noPrice: true, noPriceChart: true, communityLink: 'https://x.com/the77bit', communityNotes: 'Comming to Abstract Chain soon.', usdPair: false, priceDeicamlsformat: 6, decimalsFormat: 8, img: 'https://pbs.twimg.com/profile_images/1712518219271942144/bBIxPoV6_400x400.jpg', lpContractAddress: '', price: 0 },
    // { id: 3, name: "Onchain Heroes", noPrice: true, noPriceChart: true, communityLink: 'https://x.com/onchainheroes', communityNotes: 'Comming to Abstract Chain soon.', usdPair: false, priceDeicamlsformat: 6, decimalsFormat: 8, img: 'https://pbs.twimg.com/profile_images/1814406859949748224/X8c_j7SV_400x400.jpg', lpContractAddress: '', price: 0 },
    // { id: 2, name: "Duper", noPrice: true, noPriceChart: true, communityLink: 'https://x.com/playduper', communityNotes: 'Comming to Abstract Chain soon.', usdPair: false, priceDeicamlsformat: 6, decimalsFormat: 8, img: 'https://pbs.twimg.com/profile_images/1786133172129853440/Dym_GAe5_400x400.jpg', lpContractAddress: '', price: 0},



    // { id: 4, name: "", noPrice: true, noPriceChart: true, communityLink: 'https://x.com/', communityNotes: 'Comming to Abstract Chain soon.', usdPair: false, priceDeicamlsformat: 6, decimalsFormat: 8, img: '', lpContractAddress: '', price: 0 },

  ];

  // Function to get initial state from localStorage
  const getInitialState = () => {
    const savedState = localStorage.getItem('initialTokensUSDPairs');
    return savedState !== null ? JSON.parse(savedState) : initialTokensUSDPairs;
  };
  const [tokenPrices, setTokenPrices] = useState(getInitialState);

  useEffect(async () => {
    // const fetchTokenPrices = async (tokensList) => {
    //   setLoading(true); // Set loading to true when fetching starts

    //   // if (localStorage.getItem('initialTokensUSDPairs') == null)
    //   //   setLoading(true); // Set loading to true when fetching starts
    //   // else 
    //   //   setLoading(false)

    //   const provider = new ethers.providers.WebSocketProvider("wss://ws.pulsechain.com");
      
    //   const updatedTokens = await Promise.all(
    //     tokensList.map(async (token) => {
    //       const poolContract = new Contract(
    //         token.lpContractAddress,
    //         [
    //           'function getReserves() public view returns (uint112 _reserve0, uint112 _reserve1, uint32 _blockTimestampLast)',
    //           'function token0() external view returns (address)',
    //           'function token1() external view returns (address)',
    //         ],
    //         provider
    //       );

    //       const [reserveA, reserveB] = await poolContract.getReserves();
    //       let decimalsA = 6; // Decimals of Token A (USDC)
    //       let decimalsB = 18; // Decimals of Token B (9INCH)

    //       if (!token.usdPair) {
    //         //for now assume it'll be 18 decimals but it could be different depending on the token
    //         decimalsA = 18;
    //       } else if (token.usdPair && token.dai) {
    //         decimalsA = 18;
    //         decimalsB = 8; //Hack for now. This is for WBTC which is 8 decimals, not working atm.
    //       }

    //       const usdPrice = reserveB.mul(ethers.BigNumber.from(10).pow(decimalsA)).div(reserveA);
    //       const formattedUSDPrice = ethers.utils.formatUnits(usdPrice, decimalsB);

    //       // Calculate the price of 1 unit of Token B in terms of USDC (Token A)
    //       let pricePerUnit = reserveA.mul(ethers.BigNumber.from(10).pow(decimalsB)).div(reserveB);
    //       if (!token.usdPair) {
    //         pricePerUnit = reserveB.mul(ethers.BigNumber.from(10).pow(decimalsB)).div(reserveA); //Reverse for non-usd pairs
    //       }
    //       let formattedPricePerUnit = ethers.utils.formatUnits(pricePerUnit, decimalsA);

    //       // Store USD prices in a key-value object
    //       if (!token.usdPair) {
    //         const knownUSDPair = await fetchTokenUSDPrice(token.usdLPContractAddress);
    //         formattedPricePerUnit = knownUSDPair * formattedPricePerUnit;
    //       } 

    //       let graphqlPairData = await fetchGraphDataForPair(token.lpContractAddress);
    //       console.log("graphqlPairData", graphqlPairData);

    //       let past24HPriceData = await getPast24HPriceData(graphqlPairData, token.usdPair, token);
    //       console.log(token.name, "getPast24HPriceData(graphqlPairData", past24HPriceData)
          
    //       return { ...token, price: parseFloat(formattedPricePerUnit).toFixed(6), price24HData: past24HPriceData };
    //     })
    //   );

    //   setLoading(false); // Set loading to false when fetching completes
    //   setTokenPrices(updatedTokens);
    //   localStorage.setItem('initialTokensUSDPairs', JSON.stringify(updatedTokens));
    // };

    // fetchTokenPrices(initialTokensUSDPairs);

    setLoading(true);
    let tokens = await fetchTokenListings();
    setTokenPrices(tokens.concat(initialXTokens));
    setLoading(false);
  
  }, []);


  return (
    <Section
      bgColor={props.bgColor}
      size={props.size}
      bgImage={props.bgImage}
      bgImageOpacity={props.bgImageOpacity}
    >
      <Container>
        <Grid container justifyContent="center">
          <Grid item xs={12} sm={12} md={24}>
            <SectionHeader
              title=""
              subtitle=""
              centered
            />
            <Paper className={classes.tableContainer}>
              {loading && (
                <div className={classes.progressContainer}>       
                  {/* <Typography className={classes.footer} variant="subtitle2">Fetching latest prices from LP</Typography> */}
                  <LinearProgress color="success" classes={{ bar: classes.greenProgress }}/>
                </div>
              )}
              <TableContainer>
                <Table>
                  <TableHead >
                    <TableRow >
                      <TableCell className={classes.tableHeader} align="left">Name</TableCell>
                      <TableCell className={classes.tableHeader} align="left">Price</TableCell>
                      <TableCell className={classes.tableHeader} align="left">% 24h</TableCell>
                      <TableCell className={classes.tableHeader} align="center">Past 24H</TableCell>
                      <TableCell className={classes.tableHeader} align="center">Community</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {tokenPrices.map((token) => (
                      <TableRow key={token.id}>
                        <TableCell className={classes.tableCell} align="left">
                          <div className={classes.imageCell}>
                            <img
                              src={token.img}
                              alt="Token"
                              className={classes.tokenImage}
                            />
                            <Typography>
                              {/* <a
                                href={token.communityLink}
                                target="_blank"
                                rel="noreferrer"
                                className={classes.link}
                              > */}
                                {token.name}
                              {/* </a> */}
                            </Typography>
                          </div>
                        </TableCell>
                        <TableCell className={classes.tableCell} align="left">
                          {token.noPrice ? 'N/A' : `$${Intl.NumberFormat().format(parseFloat(token.price).toFixed(token.priceDeicamlsformat))}`}
                        </TableCell>
                        {/* <TableCell className={classes.tableCell} align="center">
                          {
                            (token.price24HData ? calculate24HPercentageMove(token.price24HData) : (token.noPriceChart == undefined ? "Computing..." : "No price data"))
                          }
                        </TableCell> */}
                        <TableCell className={classes.tableCell} align="left">
                          {token.price24HData ? (
                            (() => {
                              const percentageMove24H = calculate24HPercentageMove(token.price24HData);

                              return (
                                <span className={percentageMove24H >= 0 ? classes.positive : classes.negative}>
                                  {percentageMove24H}%
                                </span>
                              );
                            })()
                          ) : (
                            token.noPriceChart === undefined ? "" : "--"
                          )}
                        </TableCell>
                        {(!token.noPriceChart ?
                          <TableCell className={`${classes.tableCell} ${classes.chartCell}`} align="center" style={{ overflowX: "auto" }}>
                            <div className={classes.priceChart} style={{ display: "flex", alignItems: "flex-end", overflow: "hidden" }}>
                              {token.price24HData ? (
                                token.price24HData.map((price, idx) => {
                                  // Ensure prices are parsed as floats
                                  const openPrice = idx === 0 ? parseFloat(price) : parseFloat(token.price24HData[idx - 1]);
                                  const closePrice = parseFloat(price);
                                  const highPrice = Math.max(openPrice, closePrice);
                                  const lowPrice = Math.min(openPrice, closePrice);

                                  // Calculate normalized heights
                                  const allPrices = token.price24HData.map(p => parseFloat(p));
                                  const maxPrice = Math.max(...allPrices);
                                  const minPrice = Math.min(...allPrices);
                                  const range = maxPrice - minPrice;

                                  // Normalize the prices to a 0-100 scale
                                  const normalize = (value) => ((value - minPrice) / range) * 100;
                                  const normalizedOpen = normalize(openPrice);
                                  const normalizedClose = normalize(closePrice);
                                  const normalizedHigh = normalize(highPrice);
                                  const normalizedLow = normalize(lowPrice);

                                  // Determine candlestick color based on open and close prices
                                  const isGreen = closePrice >= openPrice;
                                  const candlestickColor = isGreen ? classes.greenBar : classes.redBar;

                                  // Calculate spacing between candlesticks
                                  const candleSpacing = '10px'; // Adjust as needed

                                  // Ensure candlesticks fit within the cell
                                  const candlestickHeight = 25; // Adjust as needed
                                  const normalizedHeight = candlestickHeight / 100;

                                  // Set a minimum height to ensure visibility
                                  const minHeight = 1; // Adjust as needed
                                  const bodyHeight = Math.max(Math.abs(normalizedClose - normalizedOpen), minHeight);

                                  return (
                                    <div key={idx} style={{ flex: "0 0 auto", marginLeft: candleSpacing, marginRight: candleSpacing, position: "relative", height: "100%", width: "2px", }}>
                                      {/* High wick */}
                                      <div className={classes.candlestickStick} style={{ bottom: `${normalizedHigh}%`, height: `${normalizedHigh - Math.max(normalizedOpen, normalizedClose)}%`, backgroundColor: isGreen ? '#4CAF50' : '#F44336' }} />

                                      {/* Candlestick body */}
                                      <div className={classes.candlestickBody} style={{ bottom: `${Math.min(normalizedOpen, normalizedClose)}%`, height: `${bodyHeight}%`, backgroundColor: isGreen ? '#4CAF50' : '#F44336' }} />

                                      {/* Low wick */}
                                      <div className={classes.candlestickStick} style={{ bottom: `${normalizedLow}%`, height: `${Math.abs(normalizedOpen - Math.min(normalizedOpen, normalizedClose))}%`, backgroundColor: isGreen ? '#4CAF50' : '#F44336' }} />
                                      
                                      {/* Price labels */}
                                      {idx === 0 && (
                                        <div className={classes.priceLabel} style={{ top: '-10px', }}>
                                          ${Intl.NumberFormat().format(parseFloat(openPrice).toFixed(token.decimalsFormat))}
                                        </div>
                                      )}
                                      {idx === token.price24HData.length - 1 && (
                                        <div className={classes.priceLabel} style={{ bottom: '-25px', paddingRight: '75px' }}>
                                          ${Intl.NumberFormat().format(parseFloat(closePrice).toFixed(token.decimalsFormat))}
                                        </div>
                                      )}
                                    
                                    </div>
                                  );
                                })
                              ) : (
                                  ""
                                // token.communityNotes ? <Typography variant="p">{token.communityNotes}</Typography> : ""
                              )}
                            </div>
                          </TableCell>
                          :
                          <TableCell className={`${classes.tableCell} ${classes.chartCell}`} align="center">

                            <div className={classes.tableCell} style={{ overflowX: "auto" }}>
                              {token.communityNotes ? <Typography variant="p">{token.communityNotes}</Typography> : ""}

                              {/* <img className={classes.noChartImage} src="undraw_growing_re_olpi.svg" /> */}


                              {/* {priceData ? (
                                priceData.map((price, idx) => {
                                  // Normalize bar heights
                                  const maxPrice = Math.max(...priceData);
                                  const normalizedHeight = maxPrice > 0 ? (price / maxPrice) * 100 : 0;

                                  return (
                                    <div
                                      key={idx}
                                      className={`${classes.priceBar} ${idx > 0
                                        ? price > priceData[idx - 1]
                                          ? classes.greenBar
                                          : classes.redBar
                                        : classes.greenBar // Initial bar color
                                        }`}
                                      style={{ height: `${normalizedHeight}%` }}
                                    />
                                  );
                                })
                              ) : (
                                "Loading..."
                              )} */}
                            </div>
                          </TableCell>
                          )}
                        <TableCell className={classes.tableCell} align="center">
                          <a
                            href={token.communityLink}
                            target="_blank"
                            rel="noreferrer"
                            className={classes.link}
                          >
                            <TwitterIcon fontSize="large" />
                          </a>

                        </TableCell>
                      </TableRow>
                    ))}
                    <TableRow>
                      <TableCell colSpan={5} className={classes.fullRowCell}>
                        Have a project you would like listed on Crypto Wall Street? <LinkMui component={Link} to="/contact" className={classes.link}>
                Contact Us
              </LinkMui>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
            <Typography className={classes.footer} variant="subtitle2">
              These tokens might be highly volatile. Do your own research.
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
}

export default TokenListings;
